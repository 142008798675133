import React from "react";
import { Col, Row } from "antd";
import { useIsMobileScreen } from "../../../Utilities";

interface IProps {
    icon: any,
    title: string,
    commitmentList: string[]
}
export const WorkshopCommitmentItem: React.FC<IProps> = ({
    icon,
    title,
    commitmentList
    }) => {
    const isMobileScreen = useIsMobileScreen(992);
    return (
        isMobileScreen ? (
            <Col xs={24} style={{ height: "100%", padding: "10px 0 10px", borderBottom: "1px dashed rgba(0, 0, 0, 0.06)"}}>
                <Row justify="center">
                    <p style={{ color: "var(--primary-color)", fontSize: "20px", fontWeight: "600", marginBottom: "5px"}}>
                        {title}
                    </p>
                </Row>
                <Row justify="center" style={{ paddingBottom: "10px"}}>
                    {icon}
                </Row>
                <Row>
                    {
                        commitmentList.map((commitment) => {
                            return (
                                <p style={{ fontSize: "14px", lineHeight: "17px", marginBottom: "4px"}}>
                                    {`• ${commitment}`}
                                </p>
                            )
                        })
                    }

                </Row>
            </Col>
        ) : (
            <Row style={{ height: "100%", paddingBottom: "5px", borderBottom: "1px dashed rgba(0, 0, 0, 0.06)"}}>
                <div style={{ width: "15%", minWidth: "70px", paddingTop: "8px"}}>
                    {icon}
                </div>
                <Col style={{ width: "70%", paddingTop: "4px"}}>
                    <Row>
                        <p style={{ color: "var(--primary-color)", fontSize: "20px", fontWeight: "600", marginBottom: "0"}}>
                            {title}
                        </p>
                    </Row>
                    <Row style={{ paddingLeft: "12px"}}>
                        {commitmentList.map((commitment) => {
                            return (
                                <p style={{ fontSize: "14px", lineHeight: "17px", marginBottom: "4px"}}>
                                    {`• ${commitment}`}
                                </p>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        )
    );
};
