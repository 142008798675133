import React from "react";
import { Row, Col, Form, Input } from "antd";
import { gtmAddLayer } from "../../../Utilities";

import "./styles.css";

interface IProps {}

const ResponsiveView: React.FC<IProps> = ({}) => {
  return (
    <div className="ws-location-container">
      <div style={{ width: "100%" }}>
        <div className="form-title">Ubicación</div>
        <Form.Item name={["location", "latitude"]} hidden={true} />
        <Form.Item name={["location", "longitude"]} hidden={true} />
        <Form.Item name={["location", "WorkshopStateProvince"]} hidden={true} />
        <Row id="workshop-location-form" gutter={[26, 30]}>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingresa código postal",
                },
                {
                  max: 5,
                  min: 5,
                  message: "El Código postal debe ser de 5 caracteres",
                },
                {
                  pattern: new RegExp(/^[0-9]*$/gi),
                  message: "Sólo se permiten números.",
                },
              ]}
              name={["location", "zipCode"]}
              extra="*Código postal"
            >
              <Input
                //size="large"
                placeholder="Código postal"
                disabled
                onClick={() => {
                  gtmAddLayer({
                    event: "map_postal_code_input",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingresa la Colonia",
                },
              ]}
              name={["location", "neighborhood"]}
              extra="*Colonia"
            >
              <Input placeholder="Colonia" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row id="workshop-location-form2" gutter={[26, 30]}>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingresa el Municipio o Alcaldía",
                },
              ]}
              name={["location", "municipality"]}
              extra="*Municipio o Alcaldía"
            >
              <Input
                placeholder="Municipio o Alcaldía"
                disabled
                onClick={() => {
                  gtmAddLayer({
                    event: "map_municipality_input",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingresa el Estado",
                },
              ]}
              name={["location", "city"]}
              extra="*Estado"
            >
              <Input
                placeholder="Estado"
                disabled
                onClick={() => {
                  gtmAddLayer({
                    event: "map_city_input",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row id="workshop-location-form3" gutter={[26, 30]}>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingresa la calle y el número",
                },
              ]}
              name={["location", "address"]}
              extra="*Dirección"
            >
              <input
                className="ant-input"
                name="address"
                placeholder="Dirección"
                disabled
                onClick={() => {
                  gtmAddLayer({
                    event: "map_address_input",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div id="map" className="map-container"></div>
        <div id="workshop-location-form4" className="adjust-marker-container">
          <div>Mueve el marcador para ajustar la ubicación</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ResponsiveView);
