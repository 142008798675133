import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Input } from "antd";
import { Location } from "shared/src/data-models/Workshop";
import { CatalogAPI } from "../../../Services/axios";
import { gtmAddLayer } from "../../../Utilities";
import { useWindowSize, useIsMobileScreen } from "../../../Utilities";
import ResponsiveView from "./ResponsiveView";
import { CkMessage } from "../../../../CkUI";
import "./styles.css";

declare var google: any;

let map: any = null;
let marker: any = null;
let autoComplete: any = null;
let geocoder: any = null;

interface IProps {
  location: Location;
  setWorkshop: Function;
  canEdit: boolean;
}

const MemoizedWorkshopLocation: React.FC<IProps> = ({
  location,
  setWorkshop,
  canEdit,
}) => {
  const isMobileScreen = useIsMobileScreen(992);
  const windowSize = useWindowSize();
  const [loadingMap, setLoadingMap] = useState<boolean>(true);

  useEffect(() => {
    getPosition();
  }, [isMobileScreen]);

  useEffect(() => {
    if (!canEdit && map) {
      google.maps.event.clearListeners(map, "click");
      marker.setOptions({ draggable: false });
    }
  }, [map]);

  useEffect(() => {
    if (location && map && !loadingMap) {
      let coordinates = {
        lat: location.latitude === 0 ? 19.42847 : location.latitude,
        lng: location.longitude === 0 ? -99.12766 : location.longitude,
      };
      marker.setVisible(false);
      map.setCenter(coordinates);
      map.setZoom(17);
      marker.setPosition(coordinates);
      marker.setVisible(true);
    }
  }, [location, map, loadingMap]);

  let autoCompleteRef = useRef({ value: "" } as HTMLInputElement);

  const geoCoding = (event: any) => {
    const latlng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    geocoder.geocode(
      {
        location: latlng,
      },
      (results: any, status: any) => {
        if (status === "OK") {
          if (results[0]) {
            fillAdress(
              results[0].address_components,
              event.latLng,
              results[0].formatted_address
            );
          } else {
            CkMessage({type: "error", text: "No se encontraron resultados."});
          }
        } else {
          CkMessage({type: "error", text: "Error al realizar la solicitud."});
        }
      }
    );
  };

  const setMap = (center: any) => {
    const currentMap = document.getElementById("map");

    geocoder = new google.maps.Geocoder();

    map = new google.maps.Map(currentMap, {
      center: center,
      zoom: 16,
      disableDefaultUI: false,
    });

    marker = new google.maps.Marker({
      position: center,
      draggable: true,
      map: map,
    });

    google.maps.event.addListener(map, "click", function (event: any) {
      geoCoding(event);
      sessionStorage.setItem("talleres_formchanged", "true"); // setIsTouched
      gtmAddLayer({
        event: "map_click",
      });
    });

    google.maps.event.addListener(marker, "dragend", function (event: any) {
      geoCoding(event);
      sessionStorage.setItem("talleres_formchanged", "true"); // setIsTouched
      gtmAddLayer({
        event: "map_pin_dragend",
      });
    });

    autoComplete = new google.maps.places.Autocomplete(
      autoCompleteRef.current
    ) as any;
    autoComplete.bindTo("bounds", map);
    autoComplete.setFields(["address_components", "geometry"]);
    autoComplete.addListener("place_changed", HandlePlaceSelect);
    setLoadingMap(false);
  };

  const getPosition = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMap({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          setMap({
            lat: 19.42847,
            lng: -99.12766,
          });
        }
      );
    } else {
      setMap({
        lat: 19.42847,
        lng: -99.12766,
      });
    }
  };

  const fillAdress = (
    address: Array<any>,
    coordinates: any,
    addressMarker: string = ""
  ) => {
    const loadingMessageKey = CkMessage({type: "loading", text: "Cargando dirección", duration: 15});
    let localAddress = {
      address: "",
      zipCode: "",
      latitude: "",
      longitude: "",
      city: "",
      municipality: "",
      neighborhood: "",
      WorkshopStateProvince: "",
      country: "",
    };

    let componentForm = {
      street_number: "short_name",
      route: "long_name",
      sublocality_level_1: "short_name",
      sublocality: "short_name",
      locality: "long_name",
      administrative_area_level_1: "long_name",
      country: "long_name",
      postal_code: "short_name",
    };

    for (const component of address) {
      const addressType = component.types[0] as
        | "street_number"
        | "route"
        | "sublocality_level_1"
        | "sublocality"
        | "locality"
        | "administrative_area_level_1"
        | "country"
        | "postal_code";

      if (componentForm[addressType]) {
        const val = component[componentForm[addressType]];
        switch (addressType) {
          case "postal_code":
            localAddress.zipCode = val;
            break;
          case "sublocality_level_1":
          case "sublocality":
            localAddress.neighborhood =
              localAddress.neighborhood.trim() === ""
                ? val
                : localAddress.neighborhood;
            break;
          case "locality":
            localAddress.municipality = val;
            break;
          case "administrative_area_level_1":
            localAddress.city = val;
            localAddress.WorkshopStateProvince = val;
            break;
          case "country":
            localAddress.country = val;
            break;

          default:
            break;
        }
      }
    }
    if (
      localAddress.country === "Mexico" ||
      localAddress.country === "México"
    ) {
      localAddress.address =
        addressMarker === "" ? autoCompleteRef.current.value : addressMarker;
      localAddress.latitude = coordinates.lat();
      localAddress.longitude = coordinates.lng();

      CatalogAPI.getLocationInformation(coordinates.lat(), coordinates.lng())
        .then((resp) => {
          const data = resp.data.response;
          localAddress.address = data.label ? data.label : localAddress.address;
          localAddress.zipCode = data.cp ? data.cp : localAddress.zipCode;
          localAddress.country = data.pais ? data.pais : localAddress.country;
          localAddress.city = data.estado ? data.estado : localAddress.city;
          localAddress.municipality = data.municipio
            ? data.municipio
            : localAddress.municipality;
          localAddress.WorkshopStateProvince = data.estado
            ? data.estado
            : localAddress.WorkshopStateProvince;
          localAddress.neighborhood = data.asentamiento
            ? data.asentamiento
            : localAddress.neighborhood
            ? localAddress.neighborhood
            : data.estado
            ? data.estado
            : localAddress.WorkshopStateProvince;
          setWorkshop({ location: localAddress });
        })
        .catch((err) => {
          console.error("error", err);
          CkMessage({type: "error", text: "Error al realizar la solicitud.", messageToDestroy: loadingMessageKey});
          setWorkshop({
            location: {
              address: "",
              zipCode: "",
              latitude: "",
              longitude: "",
              city: "",
              municipality: "",
              neighborhood: "",
              WorkshopStateProvince: "",
              country: "",
            },
          });
        });

      marker.setVisible(false);
      map.setCenter(coordinates);
      map.setZoom(17);
      marker.setPosition(coordinates);
      marker.setVisible(true);
    } else {
      CkMessage({type: "error", text: "La ubicación no se encuentra en Mexico.", messageToDestroy: loadingMessageKey});
      setWorkshop({
        location: {
          address: "",
          zipCode: "",
          latitude: "",
          longitude: "",
          city: "",
          municipality: "",
          neighborhood: "",
          WorkshopStateProvince: "",
          country: "",
        },
      });
    }
  };

  const HandlePlaceSelect = async () => {
    let place = autoComplete.getPlace();

    try {
      if (!place.geometry) {
        alert("No details available for input: '" + place.name + "'");
        return;
      }

      fillAdress(place.address_components, place.geometry.location);
    } catch {}
  };

  return windowSize.width > 992 ? (
    <div className="ws-location-container">
      <div style={{ width: "45%" }}>
        <div className="form-title">Ubicación</div>
        <Form.Item name={["location", "latitude"]} hidden={true} />
        <Form.Item name={["location", "longitude"]} hidden={true} />
        <Form.Item name={["location", "WorkshopStateProvince"]} hidden={true} />
        <Row id="workshop-location-form" gutter={[26, 30]}>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingresa código postal",
                },
                {
                  max: 5,
                  min: 5,
                  message: "El Código postal debe ser de 5 caracteres",
                },
                {
                  pattern: new RegExp(/^[0-9]*$/gi),
                  message: "Sólo se permiten números.",
                },
              ]}
              name={["location", "zipCode"]}
              extra="*Código postal"
            >
              <Input
                //size="large"
                placeholder="Código postal"
                disabled
                onClick={() => {
                  gtmAddLayer({
                    event: "map_postal_code_input",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingresa la Colonia",
                },
              ]}
              name={["location", "neighborhood"]}
              extra="*Colonia"
            >
              <Input placeholder="Colonia" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row id="workshop-location-form2" gutter={[26, 30]}>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingresa el Municipio o Alcaldía",
                },
              ]}
              name={["location", "municipality"]}
              extra="*Municipio o Alcaldía"
            >
              <Input
                placeholder="Municipio o Alcaldía"
                disabled
                onClick={() => {
                  gtmAddLayer({
                    event: "map_municipality_input",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingresa el Estado",
                },
              ]}
              name={["location", "city"]}
              extra="*Estado"
            >
              <Input
                placeholder="Estado"
                disabled
                onClick={() => {
                  gtmAddLayer({
                    event: "map_city_input",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row id="workshop-location-form3" gutter={[26, 30]}>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingresa la calle y el número",
                },
              ]}
              name={["location", "address"]}
              extra="*Dirección"
            >
              <input
                className="ant-input"
                name="address"
                placeholder="Dirección"
                disabled
                onClick={() => {
                  gtmAddLayer({
                    event: "map_address_input",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div id="workshop-location-form4" className="adjust-marker-container">

        </div>
      </div>
      <div style={{display: "flex", flexDirection: "column"}}>
        <p>Mueve el marcador para ajustar la ubicación</p>
        <div id="map" className="map-container"></div>
      </div>

    </div>
  ) : (
    <ResponsiveView />
  );
};

export const WorkshopLocation = React.memo(MemoizedWorkshopLocation);
