import React from "react";
import { Row, Col, Form, Input } from "antd";
import { useWindowSize } from "../../../Utilities";

import "./styles.css";

interface IProps {
  canEdit: boolean;
}

const MemoizedContactSettings: React.FC<IProps> = ({ canEdit }) => {
  const windowSize = useWindowSize();

  return (
    <div
      className="ws-contact-form"
      style={{ marginTop: windowSize.width > 992 ? "0px" : "30px" }}
    >
      <div className="form-title">Datos de contacto</div>
      <Row gutter={[26, 30]} id="workshop-contact-form">
        <Col xl={8} lg={12} md={12} xs={24} sm={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Ingresa el correo electrónico",
              },
              {
                type: "email",
                message: "Debes ingresar un correo electrónico",
              },
            ]}
            name="email"
            extra="*Correo electrónico principal"
          >
            <Input
              name="email"
              placeholder="Correo electrónico principal"
              disabled={!canEdit}
            />
          </Form.Item>
        </Col>
        <Col xl={8} lg={12} md={12} xs={24} sm={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Ingresa  el número de teléfono principal",
              },
              {
                pattern: new RegExp(/^[0-9]*$/gi),
                message: "Sólo se permiten números",
              },
              {
                max: 10,
                min: 10,
                message: "El teléfono debe ser de 10 números",
              },
            ]}
            name="workshopPhoneNumber"
            extra="*Teléfono principal"
          >
            <Input
              name="workshopPhoneNumber"
              placeholder="Teléfono principal"
              disabled={!canEdit}
            />
          </Form.Item>
        </Col>
        <Col xl={8} lg={12} md={12} xs={24} sm={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Ingresa el teléfono celular",
              },
              {
                pattern: new RegExp(/^[0-9]*$/gi),
                message: "Sólo se permiten números",
              },
              {
                max: 10,
                min: 10,
                message: "El teléfono debe ser de 10 números",
              },
            ]}
            name="customerPhoneNumber"
            extra="*Teléfono celular"
          >
            <Input
              name="customerPhoneNumber"
              placeholder="Teléfono celular"
              disabled={!canEdit}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export const ContactSettings = React.memo(MemoizedContactSettings);
