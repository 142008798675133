import React, { useState, useEffect, useRef } from "react";
import { Drawer, Button } from "antd";
import { FormInstance } from "antd/lib/form";
import PackageCard from "./PackageCard";

import "./styles.css";
import { CkIcon } from "../../../../CkUI";
import { useSelector } from "react-redux";
import { getExpandedMenu } from "../../../../stores";
import { WarrantyPreview } from "../WorkshopWarranty/WarrantyPreview";

export interface IPackage {
  servicePackageCode: string;
  servicePackage: {
    servicePackageCode: string;
    servicePackageDesc: string;
    servicePackageDescHtml: string;
    iconPath?: string;
    sortingValue?: number;
    warnings?: string;
  };
  servicePackageMap: any[];
  servicePackageSetId: number;
  servicePackageType: {
    servicePackageTypeCode: string;
    servicePackageTypeDesc: string;
  };
  servicePackageTypeCode: string;
  price?: number;
  comment?: string;
}

interface IProps {
  packageList: IPackage[];
  isOpen: boolean;
  setIsOpen: Function;
  form: FormInstance;
  saveData: Function;
  contentWrapper?: "modal" | "drawer" | "section";
  hidePrevButton?: boolean;
  prevStep?: Function;
  prevStepText?: string;
  nextStep?: Function;
  nextStepText?: string;
  savingData?: boolean;
  warrantySeeMore?: Function;
}

const WorkshopPackages: React.FC<IProps> = ({
  packageList,
  isOpen,
  setIsOpen,
  form,
  saveData,
  contentWrapper = "drawer",
  hidePrevButton = false,
  prevStep,
  prevStepText,
  nextStep,
  nextStepText,
  savingData,
  warrantySeeMore,
}) => {
  const packagesToUpdate = useRef<IPackage[]>([]);
  const isMenuExpanded = useSelector(getExpandedMenu);
  const [isNewWorkshop, setIsNewWorkshop] = useState<boolean>(true);
  const [cancelChanges, setCancelChanges] = useState<boolean>(false);
  const [formattedPackages, setFormattedPackages] = useState<Array<IPackage[]>>(
    []
  );
  const [activeKeys, setActiveKeys] = useState([]);

  /*
  useEffect(() => {
    console.log("$$packagesToUpdate[WorkshopPackages]: ", packagesToUpdate);
  }, [packagesToUpdate]);

  useEffect(() => {
    console.log("$$packageList[WorkshopPackages]: ", packageList);
  }, [packageList]);

   */

  const onCardSave = (values: any) => {
    //console.log("onCardSave[values]: ", values);
    values.forEach((item: IPackage) => {
      const index = packagesToUpdate.current.findIndex(
        (pack) => pack.servicePackageSetId === item.servicePackageSetId
      );
      if (index !== -1) {
        packagesToUpdate.current[index] = item;
      }
    });
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    if (
      values.carTypes &&
      values.carTypes.length > 0 &&
      values.email &&
      values.email.length > 0 &&
      values.workshopPhoneNumber &&
      values.workshopPhoneNumber.length > 0 &&
      values.customerPhoneNumber &&
      values.customerPhoneNumber.length > 0 &&
      values.location &&
      values.location.address.length > 0
    ) {
      setIsNewWorkshop(false);
    } else {
      setIsNewWorkshop(true);
    }
  }, [form]);

  useEffect(() => {
    if (isOpen) {
      const map: any = {};
      packagesToUpdate.current = packageList;
      packageList.forEach((item: IPackage) => {
        const packageName = item.servicePackage.servicePackageDesc;
        if (map[packageName]) {
          map[packageName].push(item);
        } else {
          map[packageName] = [item];
        }
      });
      const newPackages: IPackage[][] = [];
      for (const key in map) {
        newPackages.push(map[key]);
      }
      setFormattedPackages(newPackages);
    }
  }, [isOpen, packageList]);

  const getWarrantyInfo = (servicePackageCode: string) => {
    const warrantyInfo = form.getFieldsValue().warranty.packagesInfo;

    if (!warrantyInfo) {
      return null;
    }

    const warrantyInfoFiltered = warrantyInfo?.filter(
      (warranty: any) => warranty.servicePackageCode === servicePackageCode
    );

    if (!warrantyInfoFiltered) {
      return null;
    }

    return warrantyInfoFiltered[0];
  };

  const actions = (
    <div className="actions-container">
      {hidePrevButton === false && (
        <div
          onClick={() => {
            setCancelChanges(true);
            setIsOpen(false);
            prevStep && prevStep();
          }}
          className="cancel-style"
        >
          {prevStepText ? prevStepText : "Cancelar"}
        </div>
      )}
      {isNewWorkshop && (
        <Button
          onClick={() => {
            setIsOpen(false);
            setActiveKeys([]);
            nextStep && nextStep(packagesToUpdate.current);
          }}
          className="rhomboid-blue button-style"
          {...(savingData !== undefined ? { loading: savingData } : {})}
        >
          <div style={{ fontSize: "16px" }}>
            {nextStepText ? nextStepText : "Guardar datos"}
          </div>
        </Button>
      )}
      {!isNewWorkshop && (
        <Button
          onClick={() => {
            setIsOpen(false);
            setActiveKeys([]);
            saveData();
            nextStep && nextStep(packageList);
          }}
          className="rhomboid-blue button-style"
          {...(savingData !== undefined ? { loading: savingData } : {})}
        >
          <div style={{ fontSize: "16px" }}>
            {nextStepText ? nextStepText : "Guardar datos"}
          </div>
        </Button>
      )}
    </div>
  );

  const Header = () => (
    <>
      <div className="title">Paquetes de mantenimiento disponibles</div>
      <div
        style={{ display: "flex", flexDirection: "row", paddingTop: "20px" }}
      >
        <CkIcon name="information-round" />
        <p className="bold" style={{ paddingLeft: "10px", fontSize: "14px" }}>
          Un mantenimiento consiste en servicios estandarizados, con un precio
          definido, para el mantenimiento óptimo de un vehículo.
        </p>
      </div>
      <p style={{ paddingTop: "15px" }}>
        Selecciona todos los paquetes que ofreces en tu taller y abre sus
        paneles para ver detalles, seleccionar sus modalidades y especificar el
        precio para cada una.
      </p>
    </>
  );

  const Footer = () => (
    <div
      style={{ backgroundColor: "#EFF6FF", padding: "10px", marginTop: "20px" }}
    >
      <ul>
        <li>
          Para que tu taller aparezca en la ‘’búsqueda’’ que hacen los clientes,
          cada uno de los paquetes que ofreces debe tener el precio al que lo
          vendes considerando los productos y servicios incluidos en la
          descripción. Revísalos cuidadosamente.
        </li>
        <li>
          Ten en cuenta que el precio publicado en cada paquete es exigible por
          los clientes. Puedes añadir algo adicional o alguna aclaración en el
          cuadro de Comentarios y Condiciones.
        </li>
        <li>Todos los precios deberán tener incluido el IVA.</li>
      </ul>
    </div>
  );

  if (contentWrapper === "drawer" || contentWrapper === "modal")
    return (
      <Drawer
        open={isOpen}
        forceRender={true}
        className={
          isMenuExpanded
            ? "packages-container expanded-menu"
            : "packages-container"
        }
        closable={true}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Header />
        <div className="cards-container">
          {formattedPackages.map((item: IPackage[]) => {
            const warrantyInfo = getWarrantyInfo(item[0].servicePackageCode);

            return (
              <>
                <PackageCard
                  key={item[0].servicePackage.servicePackageCode}
                  packages={item}
                  cancelChanges={cancelChanges}
                  setCancelChanges={setCancelChanges}
                  activeKey={activeKeys}
                  setActiveKey={setActiveKeys}
                  onSave={onCardSave}
                  warrantyData={warrantyInfo}
                  onSeeMore={warrantySeeMore}
                />
              </>
            );
          })}
        </div>
        <Footer />
        {actions}
      </Drawer>
    );

  return (
    <section className="packages-container">
      <Header />
      <div className="cards-container">
        {formattedPackages.map((item: IPackage[]) => {
          const warrantyInfo = getWarrantyInfo(item[0].servicePackageCode);

          return (
            <>
              <PackageCard
                key={item[0].servicePackage.servicePackageCode}
                packages={item}
                cancelChanges={cancelChanges}
                setCancelChanges={setCancelChanges}
                activeKey={activeKeys}
                setActiveKey={setActiveKeys}
                onSave={onCardSave}
                warrantyData={warrantyInfo}
                onSeeMore={warrantySeeMore}
              />
            </>
          );
        })}
      </div>
      <Footer />
      {actions}
    </section>
  );
};

export default React.memo(WorkshopPackages);
