import React, { memo, useEffect, useState } from "react";
import { Collapse, Row, Col } from "antd";
import { useIsMobileScreen } from "../../../../app/Utilities/WindowSize";
import "./styles.css";
import { CkIcon } from "../../../../CkUI";
import PackageCardLine from "./PackageCardLine";
import { WarrantyPreview } from "../WorkshopWarranty/WarrantyPreview";

const { Panel } = Collapse;

interface Package {
  servicePackageCode: string;
  servicePackage: {
    servicePackageCode: string;
    servicePackageDesc: string;
    servicePackageDescHtml: string;
    iconPath?: string;
    sortingValue?: number;
    warnings?: string;
  };
  servicePackageMap: any[];
  servicePackageSetId: number;
  servicePackageType: {
    servicePackageTypeCode: string;
    servicePackageTypeDesc: string;
  };
  servicePackageTypeCode: string;
  price?: number;
  comment?: string;
}

interface IProps {
  packages: Package[];
  cancelChanges: boolean;
  setCancelChanges: (value: boolean) => void;
  activeKey: any;
  setActiveKey: Function;
  onSave: (values: any) => void;
  warrantyData: any;
  onSeeMore: Function;
}

const PackageCard: React.FC<IProps> = ({
  packages,
  cancelChanges,
  setCancelChanges,
  activeKey,
  setActiveKey,
  onSave,
  warrantyData,
  onSeeMore,
}) => {
  const [originalInfo, setOriginalInfo] = useState<any[]>([]);
  const [packagesList, setPackagesList] = useState<Package[]>(packages);
  const [selectedTypeCode, setSelectedTypeCode] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const isMobile = useIsMobileScreen(992);

  /*
  useEffect(() => {
    console.log("++Packages[PackageCard] ", packages[0].servicePackage.servicePackageDesc, "+");
    console.log("++Packages group[PackageCard]", packages)
  }, [packages]);

  useEffect(() => {
    console.log("++packagesList[PackageCard]: ", packagesList);
  }, [packagesList]);

   */

  useEffect(() => {
    const infoCopy = packages.map((item: Package) => {
      return {
        servicePackageTypeCode: item.servicePackageTypeCode,
        price: item.price,
        comment: item.comment,
      };
    });
    setOriginalInfo(infoCopy);
  }, [packages]);

  useEffect(() => {
    if (cancelChanges) {
      packagesList.forEach((item: Package, index: number) => {
        item.price = originalInfo[index].price;
        item.comment = originalInfo[index].comment;
      });
      setActiveKey("");
      setSelectedTypeCode("");
      setIsChecked(packages.some((item: Package) => item.price !== 0));
      setCancelChanges(false);
    }
  }, [cancelChanges]);

  useEffect(() => {
    if (packages.some((item: Package) => item.price !== 0)) {
      setIsChecked(true);
    }
  }, [packages]);

  const panelHeader = (
    <div className="panel-header">
      <CkIcon
        name={isChecked ? "added-checkmark" : "plus-add"}
        style={isChecked ? {} : { color: "#2369BD" }}
      />
      <div style={{ fontSize: "14px" }}>
        {packages[0].servicePackage.servicePackageDesc}
      </div>
    </div>
  );

  return (
    <div className="package-card-container">
      <Collapse
        accordion
        bordered
        expandIconPosition="end"
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
        expandIcon={(panelProps) => {
          console.info("panelProps", panelProps);
          return <CkIcon name="caret-down" />;
        }}
      >
        <Panel
          key={packages[0].servicePackage.servicePackageDesc}
          header={panelHeader}
          style={{ padding: "15px" }}
        >
          {/*
          <div className="card-header">
            {packagesList.map((item: Package) => (
              <div
                key={item.servicePackageTypeCode}
                onClick={() => setSelectedTypeCode(item.servicePackageTypeCode)}
              >
                <div
                  className={getTypeCodeStyle(
                    selectedTypeCode,
                    item.servicePackageTypeCode,
                    item.price as number
                  )}
                >
                  <div style={{ flexGrow: 1 }} />
                  <span>{item.servicePackageType.servicePackageTypeDesc}</span>
                  <div
                    style={{
                      flexGrow:
                        (selectedTypeCode === item.servicePackageTypeCode ||
                          item.price !== 0) &&
                          windowSize.width > 992
                          ? 0.7
                          : 1,
                    }}
                  />
                  {(selectedTypeCode === item.servicePackageTypeCode ||
                    item.price !== 0) &&
                    windowSize.width > 992 && (
                      <CheckOutlined
                        className={getCheckOutlineStyle(
                          selectedTypeCode,
                          item.servicePackageTypeCode,
                          item.price as number
                        )}
                      />
                    )}
                </div>
              </div>
            ))}
          </div>
          */}

          <div style={{ padding: "10px" }}>
            {!isMobile && (
              <Row className="header-table-packages-details">
                <Col span={5}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "var(--primary-color)",
                      fontWeight: 600,
                    }}
                  >
                    Paquete
                  </p>
                </Col>
                <Col span={7}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "var(--primary-color)",
                      fontWeight: 600,
                    }}
                  >
                    Que incluye
                  </p>
                </Col>
                <Col span={5}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "var(--primary-color)",
                      fontWeight: 600,
                    }}
                  >
                    Precio
                  </p>
                </Col>
                <Col span={6}>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "var(--primary-color)",
                      fontWeight: 600,
                    }}
                  >
                    Comentario
                  </p>
                </Col>
              </Row>
            )}
            {packagesList.map((item: Package, index) => {
              return (
                <PackageCardLine
                  key={`${item.servicePackageSetId}package-card-line-${index}`}
                  index={index}
                  packageData={item}
                  packagesList={packagesList}
                  setPackagesList={setPackagesList}
                  selectedTypeCode={selectedTypeCode}
                  setIsChecked={setIsChecked}
                  setSelectedTypeCode={setSelectedTypeCode}
                  onSave={onSave}
                />
              );
            })}
          </div>
          {/*
          <div>
            {packagesList.map((item: Package) => (
              <div key={item.servicePackageTypeCode}>
                {item.servicePackageTypeCode === selectedTypeCode && (
                  <>
                    <ul
                      className="package-description"
                      style={{ marginTop: "20px" }}
                    >
                      {item.servicePackageMap.map((item: any) => (
                        <li
                          className="package-description-item"
                          key={item.packageItemCode}
                        >
                          {item.packageItemDesc}
                        </li>
                      ))}
                    </ul>
                    <div className="price-input-container">
                      <InputNumber
                        name="priceFrom"
                        className="number-input"
                        formatter={(value: number) => `$${value}`}
                        controls={false}
                        defaultValue={item.price ?? 0}
                        placeholder="Precio con I.V.A."
                        onBlur={(e: any) => {
                          const priceSanitazed = e.target.value
                            ? parseInt(e.target.value.replace("$", ""))
                            : 0;
                          handlePriceChange(priceSanitazed);
                        }}
                        min={0}
                        max={999999}
                      />
                      <span>incluye IVA</span>
                    </div>
                    <div className="comment-section">
                      <div>Comentarios:</div>
                      <Input.TextArea
                        className="--custom"
                        name="comment"
                        defaultValue={item.comment}
                        maxLength={120}
                        onChange={(value: any) =>
                          handleCommentChange(value.target.value)
                        }
                        rows={2}
                        style={{
                          border: "1px solid #cccccc",
                          borderRadius: "6px",
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          */}
          {/*
          {selectedTypeCode !== "" && (
            <div className="card-actions">
              <div
                className="cancel-style"
                onClick={() => {
                  const currentPackageIndex = packagesList.findIndex((item: Package) => {
                    return item.servicePackageTypeCode === selectedTypeCode;
                  });
                  if (currentPackageIndex !== -1) {
                    packagesList[currentPackageIndex].price = 0;
                    packagesList[currentPackageIndex].comment = "";
                  }

                  setSelectedTypeCode("");
                  setIsChecked(
                    packages.some((item: Package) => item.price !== 0)
                  );
                }}
              >
                Descartar
              </div>
              <Button
                className="rhomboid-cancel button-style"
                onClick={handleSave}
              >
                Agregar a selección
              </Button>
            </div>
          )}
           */}
          {warrantyData && (
            <div className="warranty-preview-packages-container">
              <WarrantyPreview
                manualLabor={warrantyData.manualLabor}
                parts={warrantyData.parts}
                onSeeMore={onSeeMore}
              />
            </div>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default memo(PackageCard);
