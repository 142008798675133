import React, {memo, useEffect, useState} from "react";
import {Checkbox, InputNumber, Input, Row, Col, Divider} from "antd";
import { useIsMobileScreen } from "../../../../Utilities";
import "./styles.css";

interface Package {
  servicePackageCode: string;
  servicePackage: {
    servicePackageCode: string;
    servicePackageDesc: string;
    servicePackageDescHtml: string;
    iconPath?: string;
    sortingValue?: number;
    warnings?: string;
  };
  servicePackageMap: any[];
  servicePackageSetId: number;
  servicePackageType: {
    servicePackageTypeCode: string;
    servicePackageTypeDesc: string;
  };
  servicePackageTypeCode: string;
  price?: number;
  comment?: string;
}

interface IProps {
  packageData: any;
  packagesList: any[];
  index: number;
  setPackagesList: Function;
  onSave: Function;
  selectedTypeCode: any;
  setIsChecked: Function,
  setSelectedTypeCode: Function,
}

const PackageCardLine: React.FC<IProps> = (
  {
    packageData,
    packagesList,
    index,
    setPackagesList,
    onSave,
    selectedTypeCode,
    setIsChecked,
    setSelectedTypeCode,
  }) => {
  const isMobile = useIsMobileScreen(992);
  const [isPackageChecked, setIsPackageChecked] = useState(false);

  useEffect(() => {
    setIsPackageChecked(packageData.price && packageData.price > 0);
  }, [packageData]);

  const handleSave = () => {
    //setActiveKey("");
    if (packagesList.some((item: Package) => item.price !== 0)) {
      setIsChecked(true);
      onSave && onSave(packagesList);
    } else {
      onSave && onSave(packagesList);
    }
  };

  const handlePriceChange = (newPrice: number, packageId) => {
    const currentPackageList = [...packagesList];
    const currentPackageIndex = packagesList.findIndex((item: Package) => {
      return item.servicePackageSetId === packageId;
    });
    if (currentPackageIndex !== -1) {
      setSelectedTypeCode(currentPackageList[currentPackageIndex].servicePackageTypeCode);
      currentPackageList[currentPackageIndex].price = newPrice;
      if(newPrice === 0){
        setIsPackageChecked(false);
      }
    }
    setPackagesList(currentPackageList);
    handleSave();
  };

  const handleCommentChange = (newComment: string, packageId) => {
    const currentPackageList = [...packagesList];
    const currentPackageIndex = packagesList.findIndex((item: Package) => {
      return item.servicePackageSetId === packageId;
    });
    if (currentPackageIndex !== -1) {
      setSelectedTypeCode(packagesList[currentPackageIndex].servicePackageTypeCode);
      currentPackageList[currentPackageIndex].comment = newComment;
    }
    setPackagesList(currentPackageList);
    handleSave();
  };

  const onCheckboxClick = (e) => {
    const isChecked = e.target.checked; // true or false
    const packageId = +e.target.id;//A01

    const currentPackageList = [...packagesList];
    const currentPackageIndex = packagesList.findIndex((item: Package) => {
      return item.servicePackageSetId === packageId;
    });
    if (currentPackageIndex !== -1) {
      setSelectedTypeCode(currentPackageList[currentPackageIndex].servicePackageTypeCode);
      if(!isChecked) {
        handlePriceChange(0, packageId);
        handleCommentChange("", packageId);
        setIsPackageChecked(false);
        //currentPackageList[currentPackageIndex].price = 0;
        //[currentPackageIndex].comment = "";

        setPackagesList(currentPackageList);
        handleSave();
      }
    }
    setIsPackageChecked(!isPackageChecked);
  }

  const getDollarAmount = (packageType: "BASICO" | "PLUS" | "PREMIUM") => {
    switch (packageType) {
      case "BASICO":
        return "$"
      case "PLUS":
        return "$$"
      case "PREMIUM":
        return "$$$"
      default:
        return "$"
    }
  }

  return (
    <Row className="package-card-line">
      <Col sm={24} md={5}>
        <Row className="workshop-form-checkbox-cont">
          <Checkbox
            id={packageData.servicePackageSetId}
            checked={isPackageChecked}
            onClick={onCheckboxClick}
            className="--alter --orange"
          >
            <p style={{
              fontSize: "16px",
              margin: 0
            }}>
              Paquete {packageData.servicePackageType.servicePackageTypeDesc}
            </p>
          </Checkbox>

        </Row>
        <p style={{
          color: "#2369BD",
          fontSize: "16px",
          fontWeight: 600,
          margin: "28px 0 0 0 !important"
        }}>
          {getDollarAmount(packageData.servicePackageType.servicePackageTypeCode)}
        </p>
      </Col>

      <Col sm={24} md={7} >
        {
          isMobile && (
            <p style={{fontSize: "14px", color: "var(--primary-color)", fontWeight: 600}}>Qué incluye</p>
          )
        }
        <div style={{padding: "5px"}}>
          <ul>
            {packageData.servicePackageMap.map((servDesc) => {
              return (
                <li key={servDesc.packageItemCode}>
                  {servDesc.packageItemDesc}
                </li>
              )
            })}
          </ul>
        </div>
      </Col>

      <Col sm={24} md={5} style={{ width: "100%"}}>
        {
          isMobile && (
            <p style={{fontSize: "14px", color: "var(--primary-color)", fontWeight: 600}}>Precio</p>
          )
        }
        <Row>
          <Col sm={11} md={24}>
            <InputNumber
              style={{width: "145px !important"}}
              name="priceFrom"
              className="number-input"
              disabled={!isPackageChecked}
              formatter={(value: number) => `$${value}`}
              controls={false}
              value={packageData.price ?? 0}
              placeholder="Precio con I.V.A."
              onBlur={(e: any) => {
                const priceSanitazed = e.target.value
                  ? parseInt(e.target.value.replace("$", ""))
                  : 0;
                handlePriceChange(priceSanitazed, packageData.servicePackageSetId);
              }}
              min={0}
              max={999999}
            />
          </Col>
          <Col sm={11} md={24}>
            <span style={{ fontSize: "14px", color: "var(--primary-color)" }}>incluye IVA</span>
          </Col>
        </Row>

      </Col>

      <Col sm={24} md={6} style={{ width: "100%"}}>
        {
          isMobile && (
            <p style={{fontSize: "14px", color: "var(--primary-color)", fontWeight: 600, paddingTop: "20px"}}>Comentarios y condiciones</p>
          )
        }
        <Input.TextArea
          className="--custom"
          name="comment"
          value={packageData.comment}
          placeholder="Escribe comentarios adicionales o aclaraciones si las tienes"
          maxLength={120}
          disabled={packageData.price === 0 || packageData.price === undefined}
          onChange={(value: any) =>
            handleCommentChange(value.target.value, packageData.servicePackageSetId)
          }
          rows={6}
          style={{
            border: "1px solid #cccccc",
            borderRadius: "6px",
          }}
        />
      </Col>
      {
        (index !== 2) && (
          <Divider />
        )
      }
      {
        (index === 0) && (
          <p style={{ fontSize: "14px", fontWeight: 600}}>
            En caso de que tu taller ofrezca paquetes con beneficios adicionales para este servicio, puedes agregarlos abajo.
          </p>
        )
      }
    </Row>
  );
};

export default memo(PackageCardLine);
