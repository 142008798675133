import React, { useEffect, useState } from "react";
import { Checkbox, Select, Form } from "antd";
import { FormInstance } from "antd/lib/form";
import * as CatalogAPI from "shared/src/services/catalogsAPI";
import { useWindowSize } from "../../../Utilities";
import { VehicleBrand } from "shared";

import "./styles.css";

const { Option } = Select;

interface IProps {
  canEdit: boolean;
  form: FormInstance;
}

interface ICodeListItem {
  code: string;
  description: string;
}

const MemoizedAddtional: React.FC<IProps> = ({ form, canEdit }) => {
  const windowSize = useWindowSize();
  const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(true);
  const [vehicleBrands, setVehicleBrands] = useState<VehicleBrand[]>([]);
  const [additionalChar, setAdditionalChar] = useState<
    ICodeListItem[] | undefined
  >(undefined);
  const [characteristicsList, setCharacteristicsList] = useState<boolean[]>([
    false,
    false,
    false,
    false,
  ]);
  const [selectedVehicleBrands, setSelectedVehicleBrands] = useState<
    string[] | undefined
  >(undefined);
  const codeList: ICodeListItem[] = [
    { code: "INFO0001", description: "Recepción de vehículos 24/7" },
    { code: "INFO0002", description: "Recolección de vehículo a domicilio" },
    { code: "INFO0003", description: "Gestión de servicio de grúa" },
    { code: "INFO0004", description: "Especialización por marca (máximo 3)" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      // AUTOS, MOTOCICLETA, VEHICULOS DE CARGA LIGERA
      const vehicleBrandsRequests = await CatalogAPI.fetchVehicleBrands(
        "AUTOS"
      );
      setVehicleBrands(vehicleBrandsRequests.data || []);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const newSelectedVehicleBrands = form.getFieldValue("workshopBrands");
    const selectedCharacteristics: ICodeListItem[] =
      form.getFieldValue("additionalInfo");
    const newCharacteristicsList = codeList.map((item) =>
      selectedCharacteristics?.some((selected) => item.code === selected.code)
    );
    const additionalChar = selectedCharacteristics?.filter((selected) =>
      codeList.some((item) => item.code === selected.code)
    );
    setCharacteristicsList(newCharacteristicsList);
    setAdditionalChar(additionalChar);
    setSelectedVehicleBrands(newSelectedVehicleBrands);
  }, []);

  useEffect(() => {
    additionalChar !== undefined &&
      form.setFieldValue("additionalInfo", additionalChar);
  }, [additionalChar]);

  useEffect(() => {
    !characteristicsList[3] !== isSelectDisabled &&
      setIsSelectDisabled(!characteristicsList[3]);
  }, [characteristicsList]);

  useEffect(() => {
    selectedVehicleBrands !== undefined &&
      form.setFieldValue("workshopBrands", selectedVehicleBrands);
  }, [selectedVehicleBrands]);

  const changeData = (
    value: { code: string; description: string },
    action: string,
    index: number
  ) => {
    if (action === "add") {
      setAdditionalChar([...(additionalChar || []), value]);
    }
    if (action === "remove") {
      setAdditionalChar(
        (additionalChar || []).filter((item) => item.code !== value.code)
      );
    }
    setCharacteristicsList((prevState) => {
      prevState[index] = action === "add" ? true : false;
      return [...prevState];
    });
  };

  const changeVehicleBrandData = (value: string[]) => {
    const vehicleData = (additionalChar || []).find(
      (item) => item.code === codeList[3].code
    );
    if (vehicleData && value.length <= 3) setSelectedVehicleBrands(value);
  };

  return (
    <Form
      form={form}
      className="no-padding"
      style={{ width: windowSize.width > 992 ? "40%" : "100%" }}
    >
      <div
        className="additional-form-container"
        style={{ marginTop: windowSize.width > 992 ? 0 : "30px" }}
      >
        <div className="additional-form-title">Características adicionales</div>
        <Form.Item name="additionalInfo">
          <div className="form-box">
            <Checkbox
              checked={characteristicsList[0]}
              disabled={!canEdit}
              className="--alter --orange"
              onChange={(e: any) =>
                changeData(codeList[0], e.target.checked ? "add" : "remove", 0)
              }
            >
              Recepción de vehículos 24/7
            </Checkbox>
            <Checkbox
              checked={characteristicsList[1]}
              disabled={!canEdit}
              className="--alter --orange"
              onChange={(e: any) =>
                changeData(codeList[1], e.target.checked ? "add" : "remove", 1)
              }
            >
              Recolección de vehículo a domicilio
            </Checkbox>
            <Checkbox
              checked={characteristicsList[2]}
              disabled={!canEdit}
              className="--alter --orange"
              onChange={(e: any) =>
                changeData(codeList[2], e.target.checked ? "add" : "remove", 2)
              }
            >
              Gestión de servicio de grúa
            </Checkbox>
            <Checkbox
              checked={characteristicsList[3]}
              disabled={!canEdit}
              className="--alter --orange"
              onChange={(e: any) => {
                setIsSelectDisabled(!e.target.checked);
                changeData(codeList[3], e.target.checked ? "add" : "remove", 3);
              }}
            >
              Especialización por marca (máximo 3)
            </Checkbox>
          </div>
        </Form.Item>
        <div className="form-box">
          <Form.Item name="workshopBrands">
            <Select
              disabled={isSelectDisabled || !canEdit}
              showSearch
              mode="multiple"
              value={selectedVehicleBrands}
              onChange={(value) => changeVehicleBrandData(value)}
              placeholder="Selecciona las marcas"
              getPopupContainer={(triggerNode) => triggerNode || document.body}
            >
              {vehicleBrands &&
                vehicleBrands.map((item) => (
                  <Option
                    disabled={
                      selectedVehicleBrands &&
                      selectedVehicleBrands?.length === 3 &&
                      selectedVehicleBrands.every(
                        (selected) => selected !== item.code
                      )
                    }
                    dropdownStyle={{ textTransform: "capitalize" }}
                    value={item.code}
                    key={item.code}
                  >
                    {item.desc}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export const WorkshopAdditional = React.memo(MemoizedAddtional);
